import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useMqtt from "../hooks/useMqtt";

export const GreenHousePage = ({ tg }) => {
  const { user, ghId, ghName } = useParams();
  const [metrics, setMetrics] = useState([]);

  const onMessage = ({ topic, message }) => {
    if (topic === "63a34a539dc08a0011940de3/1/metrics") {
      const data = JSON.parse(message);
      setMetrics(data);
    }
  };

  const { client, connectStatus, mqttConnect } = useMqtt(user, onMessage);

  useEffect(() => {
    // console.log(user, gh);
    mqttConnect();
    tg.MainButton.show();
  }, []);

  useEffect(() => {
    if (!client) return;
    if (!client.connected) {
      client.unsubscribe(`${user}/${ghId}/metrics`);
    } else {
      console.log("Subscribe");
      client.subscribe(`${user}/${ghId}/metrics`);
    }
  }, [client, connectStatus]);

  return (
    <main>
      <h1 style={{ marginTop: "20px" }}>{ghName}</h1>
      {/* <h1>{connectStatus}</h1> */}

      <div className="center-container">
        {!metrics.length && <h2>Loading...</h2>}
        <ul className="metrics">
          {metrics.map((metric) => (
            <li key={metric.id}>
              <p>{metric.name}</p>
              <span>
                {metric.value}{metric.rashyot ? `/${metric.rashyot}` : ""}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
};
