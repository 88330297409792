import { useState, useEffect, useCallback } from "react";

export const LoginPage = ({ tg }) => {
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  useEffect(() => {
    if (email && password) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [email, password]);

  const onSendDataCB = useCallback(() => {
    const data = JSON.stringify({ email, password, action: "login" });
    tg.sendData(data);
  }, [email, password]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendDataCB);

    return () => {
      tg.offEvent('mainButtonClicked', onSendDataCB);
    }
  }, [onSendDataCB]);

  return (
    <main>
      <form style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
        <label htmlFor="email">Email</label>
        <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <label htmlFor="password">Password</label>
        <input id="password" type="password" value={password} onChange={(e) => setPass(e.target.value)} />
      </form>
    </main>
  );
}
