import { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from 'react-router';
import './App.css';

import { LoginPage } from "./pages/Login";
import { HomePage } from "./pages/Home";
import { GreenHousePage } from './pages/GreenHouse';

const tg = window.Telegram.WebApp;

const App = () => {
  useEffect(() => {
    tg.expand();
  }, [])

  return (
    <Routes>
      <Route path="/gh/:user/:ghId/:ghName" element={<GreenHousePage tg={tg} />} />
      <Route path="/login" element={<LoginPage tg={tg} />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  )
}

const MainAppHoc = (Component) => (props) => {
  return (
    <BrowserRouter>
      <Component {...props} />
    </BrowserRouter>
  );
};

export default MainAppHoc(App);
