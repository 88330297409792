import React, { useEffect, useState } from "react";
import * as mqtt from "mqtt";

const certConfig = {
  caFile: "",
  certFile: "",
  keyFile: "",
};

const mqttHost = process.env.REACT_APP_MQTT_HOST;
const mqttPort = process.env.REACT_APP_MQTT_PORT;
const mqttProtocol = process.env.REACT_APP_MQTT_PROTOCOL;

const useMqtt = (clientId, onMessage) => {
  const [connectStatus, setConnectStatus] = useState("");
  const [client, setClient] = useState(null);

  const mqttConnect = () => {
    const userId = `web_${clientId}_${(Math.random() * 100).toFixed()}`;
    setClient(
      mqtt.connect({
        hostname: mqttHost,
        port: mqttPort,
        protocol: mqttProtocol,
        protocolVersion: 3,
        protocolId: "MQIsdp",
        clientId: userId,
        clean: false,
        reconnectPeriod: 1000, // ms
        connectTimeout: 10 * 1000, // ms
        path: "/mqtt",
        // ...(mqttProtocol === "wss" ? certConfig : {}),
      })
    );
    setConnectStatus(`Connecting`);
  };

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        setConnectStatus("Connected");
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        setConnectStatus("Reconnecting");
      });
      client.on("message", (topic, message) => {
        const payload = { topic, message: message.toString() };
        onMessage(payload);
      });
    }
  }, [client]);

  return { connectStatus, mqttConnect, client };
};

export default useMqtt;
